import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import Hero from "../../components/ui/Hero/Hero";
import Section from "../../components/ui/Section/Section";
import ButtonLink from "../../components/ui/Buttons/ButtonLink";
import VerticalFold from "../../components/ui/VerticalFold/VerticalFold";
import ButtonLinkCard from "../../components/ui/Buttons/ButtonLinkCard";
import Questions from "../../components/blocks/Questions";
import ImageText from "../../components/blocks/ImageText";

export default ({ data }) => {

    const weiterverarbeitungen = data.allWeiterverarbeitungHJson.edges;
   
    return (
        <Layout waveBgColor={"light"} crumbs={[{ label: "Home", url: "" }, { label: "Leistungen", url: "leistungen" }, { label: "Weiterverarbeitung", url: "weiterverarbeitung"}]}>
            <Hero 
                title="Weiter&shy;verarbeitung" 
                subtitle="Bringt ihr Produkt in Form oder fügt zusätzliche Eigenschaften hinzu, die über ein Druckverfahren so nicht direkt möglich sind." 
                size="is-medium"
            />
            <Section hasTitle={true}>
                <VerticalFold textCollapsed="weitere Verarbeitungen" textOpen="weniger Verarbeitungen" elementsToShow={5} wrapper={(props) => <div className="columns is-multiline">{props.children}</div>}>

                                {weiterverarbeitungen.map((document: any) => (
                                    <div className="column is-one-quarter" key={document.node.url}>
                                        <ButtonLinkCard to={`/leistungen/weiterverarbeitung/${document.node.url}`}>
                                            <div className=" is-hidden-mobile">
                                                <h3 className="title is-4">{document.node.name}</h3>
                                                <ButtonLink to={`/leistungen/weiterverarbeitung/${document.node.url}`} variation="minimal">
                                                    <span className="is-hidden-desktop-only is-hidden-tablet-only">Mehr Erfahren</span>
                                                    <span className="is-hidden-widescreen">Mehr</span>
                                                </ButtonLink>
                                            </div>
                                            <div className="content is-hidden-tablet is-hidden-desktop">
                                                <ButtonLink to={`/leistungen/weiterverarbeitung/${document.node.url}`} variation="minimal" className="is-block"><h3 style={{marginBottom: 0}}>{document.node.name}</h3></ButtonLink>
                                            </div>
                                        </ButtonLinkCard>
                                    </div>
                                ))}
                </VerticalFold>
            </Section>

            <Questions />


            <Section size="extraSmall"  hasTitle={true}>
                <ImageText image={{src: data.images.edges[0].node, alt: "Unsere Produkte"}}>
                    <h3>Produkte aus Material, Druck und Weiterverarbeitung</h3>
                    <p>
                        In unserer Produktdatenbank haben wir unsere drei Punkte Material, Druck und Weiterverarbeitung kombiniert. Suchen Sie sich das Produkt aus welches Sie benötigen und Fragen Sie es an. Wir erstellen dann ein individuelles Angebot.
                    </p>
                    <ButtonLink to="/produkte">Zu den Produkten</ButtonLink>
                </ImageText>
            </Section>

        </Layout>
    )
}



export const pageQuery = graphql`{
  allWeiterverarbeitungHJson {
    edges {
      node {
        description
        img
        name
        type
        url
      }
    }
  }
  images: allFile(filter: {relativePath: {regex: "/leistungen/produkte.jpg/"}}) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
}
`